<template>
	<section class="section section--larger">
		<div class="container">
			<section class="section section--larger">
				<div class="container">
					<div class="columns is-multiline is-centered">
						<div class="column is-6-desktop is-12-tablet" v-for="slide in whyUsSlides" :key="slide.id">
							<div class="card">
								<div class="card-image has-text-centered px-2 py-2">
									<!-- <swiper class="swiper" autoHeight="true" spaceBetween="10" slidesPerView="auto" centeredSlides="true" :autoplay="{ delay: 2500 }">
										<swiper-slide class="swiper-slide"
											v-for="img in slide.imagine"
											:key="img.id"
										>
											<img
												:src="`${databaseLink}/${img.url}`"
												:alt="slide.nume"
											/>
										</swiper-slide>
									</swiper> -->
									<img
										:src="`${databaseLink}/${slide.imagine[0].url}`"
										:alt="slide.nume"
										width="1000"
									/>
								</div>
								<div class="card-content">
									<div class="media">
										<div class="media-content">
											<p class="title is-5">{{ slide.nume }}</p>
										</div>
									</div>
									<div class="content">
										<p>{{ slide.descriere }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			<component-certificates
				:certificates="certificates"
			></component-certificates>

			<testimonial-carousel />

			<owner-carousel />
		</div>
	</section>
</template>

<script>
	import { mapState } from 'vuex';

	import OwnerCarousel from '@/components/TheOwnerCarousel.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentCertificates from '@/components/ComponentCertificates.vue';

	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - De ce noi?`
			};
		},
		components: {
			OwnerCarousel,
			TestimonialCarousel,
			ComponentCertificates,
		},
		data() {
			return {
				email: '',
				currentIndex: 0,
				intervalId: null
			};
		},
		created() {
			console.log('AboutUs - whyUsSlides', this.whyUsSlides);
		},
		mounted() {
			// this.startCarousel();

			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		},
		computed: {
			...mapState('general', [
			'whyUsSlides',
			'certificates'
			]),
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			}
		},
		methods: {
			startCarousel() {
				this.intervalId = setInterval(() => {
					this.currentIndex = 1; //(this.currentIndex + 1) % images.length;
				}, 5000); // Change image every 5 seconds
			},
		},
	};
</script>

<style scoped lang="scss">
	.swiper {
		width: 100%;
		height: auto;
	}
	
	.swiper .swiper-slide {
		height: 300px;
		line-height: 300px;
	}

	.article {
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__meta {
			span {
				font-weight: $medium;
			}
		}
		form {
			margin-top: 2rem;
			width: 600px;
			max-width: 100%;
			.field {
				&--error {
					input,
					textarea,
					option {
						border-color: $red;
						&:focus {
							box-shadow: 0 0 0 3px rgba($red, 0.25);
						}
					}
				}
			}
			label {
				background-color: $primary;
				color: $white;
				display: block;
				font-size: 1rem;
				font-weight: $bold;
				padding: 0.75rem 1rem;
				border-width: 1px 1px 0 1px;
				border-color: $gray;
				border-style: solid;
				border-top-right-radius: $round-corners;
				border-top-left-radius: $round-corners;
				&.terms {
					color: $black;
				}
			}
			input,
			textarea,
			select {
				box-sizing: border-box;
				background: $white;
				border: 1px solid $gray;
				border-radius: $round-corners;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				color: $primary;
				display: block;
				font-size: 1rem;
				padding: 0.75rem 1rem;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 100%;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
			.error {
				color: $red;
				font-size: 0.875rem;
				font-weight: $medium;
			}
		}
	}
</style>
